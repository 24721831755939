import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from './components/atoms/Loading';

const getLazyComponent = (containerName: string) => {
	switch (containerName) {
		case 'HomePage':
			return import(/* webpackChunkName: "home" */ './pages/Home');
		case 'WebPresencePage':
			return import(/* webpackChunkName: "web-presence" */ './pages/WebPresence');
		case 'MyWorkPage':
			return import(/* webpackChunkName: "my-work" */ './pages/MyWork');
		case 'MyWorkDetail':
			return import(/* webpackChunkName: "work-detail" */ './pages/MyWorkDetail');
		default:
			return Promise.reject('No container with specified name found!');
	}
}

const App: React.FC = () => {
	const HomePage = lazy(() => getLazyComponent('HomePage'))
	const WebPresencePage = lazy(() => getLazyComponent('WebPresencePage'))
	const MyWorkPage = lazy(() => getLazyComponent('MyWorkPage'))
	const MyWorkDetail = lazy(() => getLazyComponent('MyWorkDetail'))

	return (
		<Router>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/web-presence" element={<WebPresencePage />} />
					<Route path="/my-work" element={<MyWorkPage />} />
					<Route path="/work-details/:detailid" element={<MyWorkDetail />} />
				</Routes>
			</Suspense>
		</Router>
	)
}

export default App
