import React from 'react'
import './loading.scss'

const Loading: React.FC = () => {
	return (
		<div className="load-wrapp">
			<div className="load-2">
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
		</div>
	);
}

export default Loading
